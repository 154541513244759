<template>
  <div>
    <div style="margin-bottom: 30px">编辑员工</div>
    <el-form label-width="80px" :model="ruleForm" :rules="rules" ref="ruleForm">
      <el-form-item label="员工头像">
        <label for="headImg">
          <img
            class="photo"
            :src="
              ruleForm.headImgUrl
                ? ruleForm.headImgUrl
                : require('../../../assets/image/moren.jpg')
            "
            alt
          />
        </label>
        <input
          class="file"
          style="display: none"
          id="headImg"
          name="multipartFile"
          type="file"
          @change="up1"
        />
      </el-form-item>
      <el-form-item label="姓名" prop="realnames">
        <div style="width: 300px">
          <el-input
            placeholder="请输入姓名"
            v-model="ruleForm.realnames"
            class="addInput"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="账号" prop="loginname">
        <div style="width: 300px">
          <el-input
            placeholder="请设置账号"
            v-model="ruleForm.loginname"
            class="addInput"
          ></el-input>
        </div>
      </el-form-item>

      <el-form-item label="手机号" prop="phone">
        <div style="width: 300px">
          <el-input
            maxlength="11"
            placeholder="请输入手机号"
            v-model="ruleForm.phone"
            class="addInput"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="性别" style="width: 315px" prop="sex">
        <el-radio-group v-model="ruleForm.sex" @change="sexs">
          <el-radio label="男">男</el-radio>
          <el-radio label="女">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="入职日期">
        <el-date-picker
          style="width: 300px"
          class="addInput"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="请选择入职日期"
          v-model="ruleForm.time"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="职务" prop="zhiwu">
        <el-select
          class="addInput"
          v-model="ruleForm.zhiwu"
          style="width: 300px"
          clearable
        >
          <el-option
            v-for="item in departments"
            :key="item.id"
            :label="item.post"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="职级" prop="zhiji">
        <el-select
          class="addInput"
          clearable
          v-model="ruleForm.zhiji"
          style="width: 300px"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.rank"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          style="width: 300px"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="请输入内容"
          v-model="ruleForm.textarea"
        >
        </el-input>
      </el-form-item>
      <div style="margin: 0px 0px 42px 60px">
        <el-button
          type="danger"
          @click="userEditTap('ruleForm')"
          style="width: 100px; background: red; border: red"
          >修改</el-button
        >
        <el-button
          type="danger"
          @click="FanHui()"
          style="width: 100px; background: red; border: red"
          >返回</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import { userAddLists, userAddList, userListEdit, useredit } from '../../../API/user'
import addImg from "../../../assets/image/upImg.jpg";
import headerImg from '../../../assets/image/moren.jpg';
export default {
  data () {
    //验证手机号的规则;
    var checkMobile = (rule, value, cb) => {
      // 验证手机号的正则表达式
      const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
      if (regMobile.test(value)) {
        return cb();
      }
      cb(new Error("请输入合法的手机号"));
    };
    return {
      defaultImg: headerImg,//如果图片路径错误，则返回默认图片
      ruleForm: {
        headImg: '', // 头像
        headImgUrl: addImg, // 回显地址
        loginname: '',
        realnames: '',
        sex: "男",
        zhiwu: '',
        zhiji: '',
        imageUrl: '',//头像url
        phone: '', // 手机号
        time: '',// 入职时间
        textarea: '' // 备注
      },
      id: '',// 员工id
      rules: {
        loginname: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        realnames: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '请选择性别', trigger: 'blur' }
        ],
		zhiwu: [
		  { required: true, message: '请选择职务', trigger: 'blur' }
		],
		zhiji: [
		  { required: true, message: '请选择职级', trigger: 'blur' }
		],
        phone: [
          { required: true, message: '手机号码不能为空' },
          {
            validator: checkMobile,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
      },
      departments: [], // 职务
      departmentList: [] // 职级            
    }
  },
  mounted () {
    this.CustomView();
    this.setRank();
    this.EditList();
  },
  methods: {

    up1 (e) {
      //头像
      this.ruleForm.headImg = e.target.files[0]; //图片文件
      console.log(this.ruleForm.headImg)
      this.ruleForm.headImgUrl = URL.createObjectURL(e.target.files[0]); //图片路径回显

    },
    FanHui () {
      this.$router.push("/Services/EmPloyees/?id=1")
    },
    // 查询职务
    CustomView () {
      userAddList().then(res => {
        this.departments = res.data.data
        console.log(this.departments);
      })
    },
    // 查询职级
    setRank () {
      userAddLists().then(res => {
        this.departmentList = res.data.data
      })
    },
    // 回显员工信息
    EditList () {
      this.id = this.$route.query.eid

      userListEdit(this.id).then(res => {
        this.ruleForm.realnames = res.data.data.realname
        this.ruleForm.loginname = res.data.data.loginname
        this.ruleForm.phone = res.data.data.phone
        this.ruleForm.sex = res.data.data.sex
        this.ruleForm.time = res.data.data.entrytime
        this.ruleForm.zhiwu = res.data.data.post_id
        this.ruleForm.zhiji = res.data.data.rank_id
       
        if(res.data.data.headimg == ''){
          this.ruleForm.headImgUrl == this.defaultImg
        }else{
          this.ruleForm.headImgUrl = this.URL + res.data.data.headimg
        }
        this.ruleForm.textarea = res.data.data.remarks
      })

    },
    //编辑员工
    userEditTap (ruleForm) {
      this.$refs[ruleForm].validate((valid, obj) => {
        if (valid) {
          var formdata = new FormData()
          formdata.append('id', this.id);
          formdata.append('loginname', this.ruleForm.loginname);
          formdata.append('realname', this.ruleForm.realnames);
          formdata.append('sex', this.ruleForm.sex);
          formdata.append('post', this.ruleForm.zhiwu);
          formdata.append('rank', this.ruleForm.zhiji,);
          formdata.append('phone', this.ruleForm.phone);
          formdata.append('entrytime', this.ruleForm.time);
          formdata.append('file', this.ruleForm.headImg);
          formdata.append('remarks', this.ruleForm.textarea);
          //添加
          this.$axios({
            method: "post",
            url: "/facilitator/ModifyStaffXX",
            data: formdata,
            headers: {
              "Content-Type": "multipart/form-data",
            }
          }).then(res => {
            if (res.data.code == 0) {
              this.$message({
                type: 'info',
                message: '修改成功',
                type: 'success'
              });
              this.$router.push("/Services/EmPloyees/?id=1")
            } else {
              this.$message(res.data.msg);
            }

          })


        }
      })

    },
    sexs (e) {
      console.log(e);
    },
  },
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.headerImg {
  object-fit: cover;
  object-position: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 50px 150px;
}
.headerInput {
  position: absolute;
  top: 220px;
  left: 43%;
  right: 0;
  width: 8%;
  height: 35%;
  border-radius: 50%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
</style>