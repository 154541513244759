import request from '@/utils/request'
import qs from 'qs'


// 员工列表
export function UserList (id,page,limit,realname,loginname) {
  let data = {
    id,page,limit,realname,loginname
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/showstaff',
    method: 'post',
    data
  })
}
// 开启关闭账号
export function OpenList (id,state) {
  let data = {
    id,state
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/openstaff',
    method: 'post',
    data
  })
}
//添加员工
export function userAdd (formdata) {
 
  data = qs.stringify(data)
  return request({
    url: '/facilitator/addstaff',
    method: 'post',
    data:formdata
  })
}

//编辑员工信息
export function useredit (id,loginname,realname,sex,post,rank,phone,entrytime,file,remarks) {
  let data = {
    id,loginname,realname,sex,post,rank,phone,entrytime,file,remarks
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/ModifyStaffXX',
    method: 'post',
    data
  })
}
// 回显员工信息
export function userListEdit (id) {
  let data = {
    id
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/OneStaff',
    method: 'post',
    data
  })
}
// 查询职级
export function userAddLists () {
  let data = {

  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/ListRank',
    method: 'post',
    data
  })
}
// 查询职务
export function userAddList () {
  let data = {

  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/ListPost',
    method: 'post',
    data
  })
}
// 重置密码
export function openPassworsAdd (id,password) {
  let data = {
    id,password
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/modifystaffmm',
    method: 'post',
    data
  })
}

// 查询员工订单
export function UserLists (id,page,limit) {
  let data = {
    id,page,limit
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/staffdingdanxq',
    method: 'post',
    data
  })
}