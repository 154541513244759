<template>
  <div class="right">
    <div v-show="tab == 0">
      <div class="yue">
        <div>
          <span>员工信息</span>
        </div>
        <div class="status_title">
          <el-form
            size="mini"
            :inline="true"
            label-width="80px"
            class="demo-form-inline"
          >
            <el-form-item label="员工姓名">
              <el-input v-model="realnames" placeholder="员工姓名"></el-input>
            </el-form-item>
            <el-form-item label="员工账号">
              <el-input v-model="loginname" placeholder="员工账号"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="danger" @click="UserList">查询</el-button>
            </el-form-item>
            <!--            <div style="width: 100px; float: right">-->
            <el-form-item>
              <el-button type="danger" @click="TixianTap">增加员工</el-button>
              <!--                style="width: 100px;float: right; background: red; border: red"-->
            </el-form-item>
            <!--            </div>-->
            <!--            <br/>-->
          </el-form>
        </div>
        <!--        <div class="yue-list">-->
        <!--          <div style="width: 100px; float: left">-->
        <!--            <el-button-->
        <!--              type="danger"-->
        <!--              @click="TixianTap"-->
        <!--              style="width: 100px; background: red; border: red"-->
        <!--              >增加员工</el-button-->
        <!--            >-->
        <!--          </div>-->
        <!--        </div>-->
      </div>

      <div style="margin-top: 27px">
        <div class="title">
          <div class="container_table">
            <el-table
              :header-cell-style="headeRowClass"
              :data="tableData"
              stripe
              style="width: 100%"
              :default-sort="{ prop: 'date', order: 'descending' }"
            >
              <el-table-column prop="realname" label="员工姓名" align="center">
              </el-table-column>

              <el-table-column prop="loginname" label="账号"> </el-table-column>
              <el-table-column prop="sex" label="性别" sortable>
              </el-table-column>
              <el-table-column prop="sex" label="等级" sortable>
              </el-table-column>
              <el-table-column prop="post" label="职务" sortable>
              </el-table-column>
              <el-table-column prop="rank" label="职级" sortable>
              </el-table-column>
              <el-table-column label="状态" sortable>
                <template slot-scope="scope">
                  {{ scope.row.state == 1 ? "正常" : "投诉处理中" }}
                </template>
              </el-table-column>
              <el-table-column
                prop="entrytime"
                label="入职时间"
                sortable
                width="150"
              >
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <span
                    class="editText"
                    type="text"
                    size="small"
                    @click="openPassword(scope.row)"
                    style="margin-right: 10px; cursor: pointer; color: #409eff"
                    >重置密码</span
                  >
                  <span
                    class="editText"
                    @click="EditTap(scope.row)"
                    type="text"
                    size="small"
                    style="margin-right: 10px; cursor: pointer; color: #409eff"
                    >编辑</span
                  >
                  <span
                    style="cursor: pointer; margin-right: 10px; color: #409eff"
                    class="editText ml10"
                    type="text"
                    size="small"
                    v-if="scope.row.state == 1"
                    @click="open(scope.row, '0')"
                    >关闭</span
                  >

                  <span
                    style="cursor: pointer; margin-right: 10px; color: #409eff"
                    class="deleteText ml10"
                    type="text"
                    size="small"
                    v-if="scope.row.state == 0"
                    @click="open(scope.row, '1')"
                    >开启</span
                  >
                  <span
                    style="cursor: pointer; color: #409eff"
                    class="editText ml10"
                    type="text"
                    size="small"
                    v-if="scope.row.state == 1"
                    @click="deleteUser(scope.row, '0')"
                    >删除</span
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              class="fy"
              small
              @size-change="handleSizeChange"
              :page-size="pagesize"
              :page-sizes="[10, 20, 30]"
              layout="total, sizes, prev, pager, next, jumper"
              @current-change="current_change"
              :total="total"
              background
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div v-show="tab == 1">
      <EmPloyeesAdd @back="aa"></EmPloyeesAdd>
    </div>
    <div v-show="tab == 2">
      <EmPloyeesEdit @back="aa" ref="lookDetail"></EmPloyeesEdit>
    </div>
  </div>
</template>

<script>
import { UserList, OpenList, openPassworsAdd } from "../../../API/user";
import EmPloyeesAdd from "./EmPloyeesAdd";
import EmPloyeesEdit from "./EmPloyeesEdit";
export default {
  components: {
    EmPloyeesAdd,
    EmPloyeesEdit,
  },
  data() {
    return {
      activeName: "first", //默认选项卡初始化1
      value3: "",
      loading: false,
      tab: 0, //0 列表   1提现页面
      total: 0, //默认数据总数
      pagesize: 10, //每页的数据条数
      currentPage: 1, //默认开始页面
      tableData: [], //列表
      dialog: false,
      realnames: "", // 员工名字
      loginname: "", //账号
    };
  },
  mounted() {
    this.UserList();
  },
  methods: {
    aa() {
      this.tab = 0;
      this.UserList();
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 更改表头样式
    headeRowClass({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F2F2F2;color: #666666;font-weight: 700;";
      }
    },
    // 分页
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.UserList();
    },
    handleSizeChange(val) {
      console.log(val);
      this.pagesize = val;
      this.UserList();
    },
    // 增加员工
    TixianTap() {
      this.$router.push("/Services/EmPloyeesAdd/?id=1");
    },
    EditTap(row) {
      let eid = row.id;
      this.$router.push({
        path: "/Services/EmPloyeesEdit",
        query: { id: 1, eid: eid },
      });
    },
    //重置密码
    //接收弹窗
    receive: function (row) {
      //显示弹框
      this.dialog = true;
    },
    //员工列表
    UserList() {
      let id = localStorage.getItem("FuWuId");
      UserList(
        id,
        this.currentPage,
        this.pagesize,
        this.realnames,
        this.loginname
      ).then((res) => {
        this.tableData = res.data.data;
        this.total = res.data.total;
        console.log(res);
        console.log(this.tableData);
      });
    },
    openPassword(row) {
      this.$confirm("密码将重置为默认密码：1qaz?", "重置密码", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          openPassworsAdd(row.id, "1qaz").then((res) => {
            if (res.data.code == 0) {
              this.$message({
                type: "info",
                message: "密码重置成功",
                type: "success",
              });
              this.UserList(); //重新加载数据
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 删除员工
    deleteUser() {
      this.$confirm("删除后将不可撤销，是否继续？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {});
    },
    //开启、关闭
    open(row, status) {
      if (status === "0") {
        console.log("冻结");
        this.$confirm("关闭后将取消员工登录权限，后续可重新开启?", "关闭员工", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            OpenList(row.id, 0).then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "info",
                  message: "关闭成功",
                  type: "success",
                });
                this.UserList(); //重新加载数据
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      } else {
        console.log("解冻");
        this.$confirm("开启后员工将重新获取登录权限，确定开启吗?", "开启员工", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            OpenList(row.id, 1).then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "info",
                  message: "开启成功",
                  type: "success",
                });
                this.UserList(); //重新加载数据
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
  },
};
</script>

<style  scoped>
.yue {
  width: 100%;
  height: 110px;
}
.yue-list {
  width: 100%;
  background: saddlebrown;
  margin-top: 30px;
}
.status_title {
  margin-top: 20px;
}
.fy {
  text-align: center;
  margin-top: 50px;

  margin-bottom: 27px;
}
</style>